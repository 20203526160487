import Vue from 'vue';
import Router from 'vue-router';
// @ts-ignore
import vbclass from 'vue-body-class';
import { bloqifyAuth, firebase } from '@/boot/firebase';

/**
 * Custom scroll handler, to be able to use
 * URLs with a hash in it (e.g. /sandbox#alerts).
 *
 * @see https://router.vuejs.org/api/#scrollbehavior
 * @param to
 * @param from
 * @param savedPosition
 */
const scrollBehavior = (to: any, from: any, savedPosition: any): any => {
  // If hash is present, and not the one used by
  // Bootstrap tabs (see tabs.ts), use hash selector
  if (to.hash && !to.hash.startsWith('#/')) {
    return { selector: to.hash };
  }

  return { x: 0, y: 0 };
};

/**
 * Initialize Vue router.
 *
 * @see https://router.vuejs.org
 */
const VueRouter = new Router({
  mode: 'history',
  scrollBehavior,
  base: process.env.BASE_URL,
  routes: [
    {
      path: '*',
      redirect: '/404',
    },
    {
      path: '/',
      redirect: '/login',
    },
    {
      path: '/login',
      name: 'login',
      component: (): Promise<object> => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
      meta: {
        layout: 'single-fluid',
        bodyClass: 'page--login',
        title: 'Login',
      },
    },
    {
      path: '/reset',
      name: 'reset',
      component: (): Promise<object> => import(/* webpackChunkName: "reset" */ '@/views/Reset.vue'),
      meta: {
        layout: 'single-fluid',
        bodyClass: 'page--reset',
        title: 'Reset password',
      },
    },
    {
      path: '/dashboard',
      name: 'dashboard',
      component: (): Promise<object> => import(/* webpackChunkName: "dashboard" */ '@/views/Dashboard.vue'),
      meta: {
        layout: 'sidebar-wide',
        bodyClass: 'page--dashboard',
        title: 'Dashboard',
        requiresAuth: true,
      },
    },
    {
      path: '/investments',
      name: 'investments',
      component: (): Promise<object> => import(/* webpackChunkName: "investments" */ '@/views/Investments.vue'),
      redirect: '/investments/all',
      meta: {
        layout: 'sidebar-wide',
        bodyClass: 'page--investments',
        title: 'Investments',
        requiresAuth: true,
      },
      children: [
        {
          path: 'all',
          name: 'investments-all',
          component: (): Promise<object> => import(/* webpackChunkName: "investments" */ '@/components/investments/All.vue'),
          meta: {
            layout: 'sidebar--wide',
            bodyClass: 'page--investments-all',
            title: 'Investments all',
          },
        },
        {
          path: 'loans',
          name: 'investments-loans',
          component: (): Promise<object> => import(/* webpackChunkName: "investments" */ '@/components/investments/Loans.vue'),
          meta: {
            layout: 'sidebar--wide',
            bodyClass: 'page--investments-loans',
            title: 'Investments loans',
          },
        },
      ],
    },
    {
      path: '/investments/create-modify-payment/:investmentId?/:paymentId?',
      name: 'create-modify-payment',
      component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/CreatePayment.vue'),
      meta: {
        layout: 'sidebar--wide',
        bodyClass: 'page--create-payment',
        title: 'Create & Modify Payment',
        requiresAuth: true,
      },
    },
    {
      path: '/users',
      name: 'users',
      component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/Users.vue'),
      redirect: '/users/all',
      meta: {
        layout: 'sidebar-wide',
        bodyClass: 'page--users',
        title: 'Users',
        requiresAuth: true,
      },
      children: [
        {
          path: 'all',
          name: 'users-all',
          component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/components/users/TabAllInvestors.vue'),
          meta: {
            layout: 'sidebar--wide',
            bodyClass: 'page--users-all',
            title: 'Users all',
          },
        },
      ],
    },
    {
      path: '/user-details/:userId/',
      name: 'user-details',
      component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/UserDetails.vue'),
      props: { approvalMode: false },
      meta: {
        layout: 'sidebar--wide',
        bodyClass: 'page--user-details',
        title: 'User Details',
        requiresAuth: true,
      },
    },
    {
      path: '/user-details/:userId/edit',
      name: 'user-details-edit',
      component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/CreateUser.vue'),
      props: { approvalMode: false },
      meta: {
        layout: 'sidebar--wide',
        bodyClass: 'page--user-details',
        title: 'Edit User Details',
        requiresAuth: true,
      },
    },
    {
      path: '/user-details/:userId/approval',
      name: 'user-details-approval',
      component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/UserDetails.vue'),
      props: { approvalMode: true },
      meta: {
        layout: 'sidebar--wide',
        bodyClass: 'page--user-details',
        title: 'Approve User',
        requiresAuth: true,
      },
    },
    {
      path: '/user-create',
      name: 'user-create',
      component: (): Promise<object> => import(/* webpackChunkName: "users" */ '@/views/CreateUser.vue'),
      props: { approvalMode: true },
      meta: {
        layout: 'sidebar--wide',
        bodyClass: 'page--user-details',
        title: 'Approve User',
        requiresAuth: true,
      },
    },
    {
      path: '/assets',
      name: 'assets',
      component: (): Promise<object> => import(/* webpackChunkName: "assets" */ '@/views/Assets.vue'),
      meta: {
        layout: 'sidebar-wide',
        bodyClass: 'page--assets',
        title: 'Assets',
        requiresAuth: true,
      },
    },
    {
      path: '/blockchain',
      name: 'blockchain',
      component: (): Promise<object> => import(/* webpackChunkName: "blockchain" */ '@/views/Blockchain.vue'),
      meta: {
        layout: 'sidebar-wide',
        bodyClass: 'page--blockchain',
        title: 'Blockchain',
        requiresAuth: true,
      },
    },
    {
      path: '/create-modify-asset/:assetId?',
      name: 'create-modify-asset',
      component: (): Promise<object> => import(/* webpackChunkName: "create-asset" */ '@/views/CreateAsset.vue'),
      meta: {
        layout: 'sidebar--wide',
        bodyClass: 'page--create-asset',
        title: 'Create & Modify Asset',
        requiresAuth: true,
      },
    },
    {
      path: '/asset-financials/:assetId?',
      name: 'asset-financials',
      component: (): Promise<object> => import(/* webpackChunkName: "asset-financial" */ '@/views/AssetFinancials.vue'),
      meta: {
        layout: 'sidebar--wide',
        bodyClass: 'page--asset-financials',
        title: 'Asset Financials',
        requiresAuth: true,
      },
    },
    {
      path: '/managers',
      name: 'Managers',
      component: (): Promise<object> => import(/* webpackChunkName: "managers" */ '@/views/Managers.vue'),
      meta: {
        layout: 'sidebar-wide',
        bodyClass: 'page--managers',
        title: 'Managers',
        requiresAuth: true,
      },
    },
    {
      path: '/tracing',
      name: 'Tracing',
      component: (): Promise<object> => import(/* webpackChunkName: "tracing" */ '@/views/Tracing.vue'),
      meta: {
        layout: 'sidebar-wide',
        bodyClass: 'page--tracing',
        title: 'Tracing',
        requiresAuth: true,
      },
    },
    {
      path: '/settings',
      name: 'Settings',
      component: (): Promise<object> => import(/* webpackChunkName: "settings" */ '@/views/Settings.vue'),
      redirect: '/settings/general',
      meta: {
        layout: 'sidebar',
        bodyClass: 'page--settings',
        title: 'Settings',
        requiresAuth: true,
      },
      children: [
        {
          path: 'general',
          name: 'settings-general',
          component: (): Promise<object> => import(/* webpackChunkName: "settings" */ '@/components/account/settings/General.vue'),
          meta: {
            layout: 'sidebar--wide',
            bodyClass: 'page--settings-general',
            title: 'Settings general',
          },
        },
        {
          path: 'security',
          name: 'settings-security',
          component: (): Promise<object> => import(/* webpackChunkName: "settings" */ '@/components/account/settings/Security.vue'),
          meta: {
            layout: 'sidebar--wide',
            bodyClass: 'page--settings-security',
            title: 'Settings security',
          },
        },
        {
          path: 'messaging',
          name: 'settings-messaging',
          component: (): Promise<object> => import(/* webpackChunkName: "settings" */ '@/components/account/settings/Messaging.vue'),
          meta: {
            layout: 'sidebar--wide',
            bodyClass: 'page--settings-messaging',
            title: 'Settings messaging',
          },
        }, {
          path: 'questionnaire',
          name: 'settings-questionnaire',
          component: (): Promise<object> => import(/* webpackChunkName: "settings" */ '@/components/account/settings/Questionnaire.vue'),
          meta: {
            layout: 'sidebar--wide',
            bodyClass: 'page--settings-questionnaire',
            title: 'Settings questionnaire',
          },
        },
        {
          path: 'restore-backup',
          name: 'settings-restore-backup',
          component: (): Promise<object> => import(/* webpackChunkName: "settings" */ '@/components/account/settings/RestoreBackup.vue'),
          meta: {
            layout: 'sidebar--wide',
            bodyClass: 'page--settings-restore-backup',
            title: 'Settings restore backup',
          },
        },
      ],
    },
    {
      path: '/sandbox',
      name: 'sandbox',
      component: (): Promise<object> => import(/* webpackChunkName: "sandbox" */ '@/views/Sandbox.vue'),
      meta: {
        layout: 'sidebar',
        bodyClass: 'page--sandbox',
        title: 'Sandbox',
        requiresAuth: true,
      },
    },
    {
      path: '/e2e',
      name: 'e2e',
      component: (): Promise<object> => import(/* webpackChunkName: "sandbox" */ '@/views/E2e.vue'),
      meta: {
        layout: 'single-fluid',
        bodyClass: 'page--e2e',
        title: 'Browser testing',
        requiresAuth: false,
      },
    },
    {
      path: '/404',
      name: '404',
      component: (): Promise<object> => import(/* webpackChunkName: "404" */ '@/views/NotFound.vue'),
      meta: {
        layout: 'single',
        bodyClass: 'page--404',
        title: 'Page not found',
      },
    },
  ],
});

VueRouter.beforeEach(async (to, from, next): Promise<void> => {
  const { currentUser } = bloqifyAuth;
  const requiresAuth = to.matched.some((record): boolean => record.meta.requiresAuth);

  // Checks if the user is enrolled in PHONE multifactor
  const enrolledMultifactor = currentUser?.multiFactor.enrolledFactors.some(
    (multiFactor): boolean => multiFactor.factorId === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID,
  );

  const tokenResult = await currentUser?.getIdTokenResult();
  const tokenClaims = tokenResult?.claims;
  const isAdmin = !!tokenClaims?.superadmin || !!tokenClaims?.admin || !!tokenClaims?.editor;

  if (requiresAuth && (!currentUser || !isAdmin || !enrolledMultifactor)) {
    // Need to login
    next('login');
  } else if (!requiresAuth && currentUser && enrolledMultifactor && from.path === '/login' && to.path !== '/404') {
    // After login go to dashboard
    next('dashboard');
  } else if (currentUser && enrolledMultifactor && to.path === '/login') {
    // Go to dashboard when requesting login page while being logged in
    next('dashboard');
  } else {
    next();
  }
});

// Use Vue router instance and init Vue body class component
// and Vue page titles component to set page classes and titles
Vue.use(Router);
Vue.use(vbclass, VueRouter);

export default VueRouter;
