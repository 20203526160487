import { Module } from 'vuex';
import to from 'await-to-js';
import moment from 'moment/moment';
import BigNumber from 'bignumber.js';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { generateState, mutateState, Vertebra } from '@/store/utils/skeleton';
import { Earning, Investment, Payment, PaymentStatus } from '@/models/investments/Investment';
import { Asset } from '@/models/assets/Asset';
import { Dividend } from '@/models/assets/Dividends';
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;

const SET_DIVIDENDS = 'SET_DIVIDENDS';

export interface UpdateDividendParam {
  newTotalAmount: number,
  newTaxes: number,
  newCosts: number,
  assetId: string,
  dividendId: string,
  period: Date,
}

export interface DeleteDividendParam {
  assetId: string,
  dividendId: string,
}

export interface AddDividendParam {
  amount: number;
  assetId: string;
  period: Date;
  costs: number;
  taxRate: number;
}

interface EarningExtended extends Earning {
  id: string,
  ref: DocumentReference,
}

/** Helper functions */
const createEarning = (
  amount: Earning['amount'],
  period: Date,
): Pick<Earning, 'updatedDateTime' | 'deleted' | 'amount' | 'period'> => {
  const dateNow = firebase.firestore.Timestamp.now();
  return {
    updatedDateTime: dateNow,
    deleted: false,
    period: firebase.firestore.Timestamp.fromDate(period),
    amount,
  };
};

const getEarnings = async (dividendRef: DocumentReference<Dividend>): Promise<EarningExtended[]> => {
  // Query earnings
  const [earningsQueryError, earningsQuery] = await to(bloqifyFirestore.collectionGroup('earnings')
    .where('dividend', '==', dividendRef)
    .get() as Promise<firebase.firestore.QuerySnapshot<Earning>>);

  if (earningsQueryError || !earningsQuery) {
    throw Error(earningsQueryError?.message || 'There was an error fetching the earnings');
  }

  return earningsQuery.docs.map((doc): EarningExtended => ({
    ...doc.data(),
    id: doc.id,
    ref: doc.ref,
  }));
};

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_DIVIDENDS](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addDividend(
      { commit }, { amount, assetId, period, costs, taxRate }: AddDividendParam,
    ): Promise<void> {
      commit(SET_DIVIDENDS, { status: DataContainerStatus.Processing, operation: 'addDividend' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId) as DocumentReference<Asset>;
      const newDividendRef = assetRef.collection('dividends').doc() as DocumentReference<Dividend>;

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        // Read asset
        const [assetError, assetSnapshot] = await to(transaction.get(assetRef));

        if (assetError || !assetSnapshot || !assetSnapshot.exists) {
          throw Error(assetError?.message || 'This asset does not exist');
        }

        // Query investments
        // eslint-disable-next-line max-len
        const [investmentsQueryError, investmentsQuery] = await to(
          (bloqifyFirestore.collection('investments') as firebase.firestore.CollectionReference<Investment>)
          .where('asset', '==', assetRef)
          .get(),
        );

        if (investmentsQueryError || !investmentsQuery || investmentsQuery.empty) {
          throw Error(investmentsQueryError?.message || 'There are no investments for this asset');
        }

        // The total value needed to determine how many euros each investor gets
        const totalValueShares = assetSnapshot.get('totalValueShares') as Asset['totalValueShares'] || 0;
        const totalDividendAmount = assetSnapshot.get('totalDividendAmount') as Asset['totalDividendAmount'] || 0;

        const amountMinusCosts = new BigNumber(amount).minus(costs);
        const taxRateAmount = amountMinusCosts.times(taxRate).dividedBy(100);
        const netAmount = amountMinusCosts.minus(taxRateAmount);

        const investmentsData = (await Promise.all(investmentsQuery.docs.map(async (investmentSnapshot): Promise<any> => {
          const investorRef = investmentSnapshot.get('investor');

          const [getInvestmentPaymentsError, getInvestmentPaymentsSuccess] = await to(investmentSnapshot.ref.collection('payments').get());

          if (getInvestmentPaymentsError || getInvestmentPaymentsSuccess?.empty) {
            return null;
          }

          return {
            payments: getInvestmentPaymentsSuccess!.docs.map((payment): Payment => payment.data() as Payment),
            investorRef,
            investmentSnapshot,
          };
        }))).filter((investmentData): boolean => !!investmentData);

        investmentsData.forEach(({ payments, investmentSnapshot, investorRef }): void => {
          const sharesAmount = payments.reduce((accumulator, paymentData): BigNumber => {
            const isPaid = paymentData.providerData.status === PaymentStatus.Paid;
            const isInRange = paymentData.paymentDateTime && moment(paymentData.paymentDateTime!.toDate()).isSameOrBefore(moment(period));

            if (isPaid && isInRange) {
              return accumulator.plus(paymentData.providerData.metadata.sharesAmount);
            }

            return accumulator;
          }, new BigNumber(0));

          // Calculate earning amount and round it with 2 digit precision
          const earningAmount = sharesAmount.dividedBy(totalValueShares).times(amount);
          const earningCostsAmount = sharesAmount.dividedBy(totalValueShares).times(costs);
          const earningTaxesAmount = sharesAmount.dividedBy(totalValueShares).times(taxRateAmount);
          const earningNetAmount = earningAmount.minus(earningCostsAmount).minus(earningTaxesAmount);

          const totalEarnings = investmentSnapshot.get('totalEarnings') || 0;
          const totalNetEarnings = investmentSnapshot.get('totalNetEarnings') || 0;

          transaction.update(investmentSnapshot.ref, {
            totalEarnings: new BigNumber(totalEarnings).plus(earningAmount).toNumber(),
            totalNetEarnings: new BigNumber(totalNetEarnings).plus(earningNetAmount).toNumber(),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });

          transaction.set(investmentSnapshot.ref.collection('earnings').doc() as DocumentReference<Earning>, {
            amount: earningAmount.toNumber(),
            costs: earningCostsAmount.toNumber(),
            taxAmount: earningTaxesAmount.toNumber(),
            netAmount: earningNetAmount.toNumber(),
            period: Timestamp.fromDate(period),
            deleted: false,
            dividend: newDividendRef,
            investor: investorRef,
            investment: investmentSnapshot.ref,
            createdDateTime: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
          });
        });

        transaction.update(assetRef, {
          totalDividendAmount: new BigNumber(totalDividendAmount).plus(amount).toNumber(),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        transaction.set(newDividendRef, {
          amount,
          costs,
          taxRate,
          netAmount: netAmount.toNumber(),
          deleted: false,
          asset: assetRef,
          period: firebase.firestore.Timestamp.fromDate(period),
          createdDateTime: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp() as Timestamp,
        });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'addDividend',
        });
      }

      return commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'addDividend',
      });
    },
    async updateDividend(
      { commit },
      { newTotalAmount, newTaxes, newCosts, assetId, dividendId, period }: UpdateDividendParam,
    ): Promise<void> {
      commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Processing,
        operation: 'updateDividend',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const dividendRef = assetRef.collection('dividends').doc(dividendId) as DocumentReference<Dividend>;

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        // Read dividend
        const [dividendSnapshotError, dividendSnapshot] = await to(transaction.get<Dividend>(dividendRef));

        if (dividendSnapshotError || !dividendSnapshot || !dividendSnapshot.exists) {
          throw Error(dividendSnapshotError?.message || 'There was an error fetching the dividend');
        }

        const oldPeriod = dividendSnapshot.get('period') as Dividend['period'];
        const oldTotalAmount = dividendSnapshot.get('amount');
        const newToOldRatio = new BigNumber(newTotalAmount).dividedBy(oldTotalAmount).toNumber(); // => ratio * oldAmount = newAmount

        // Read asset
        const [assetError, assetSnapshot] = await to(transaction.get(assetRef));

        if (assetError || !assetSnapshot || !assetSnapshot.exists) {
          throw Error(assetError?.message || 'There was an error fetching the asset');
        }

        // The difference between the two interest amounts (positive if the amount increased, negative otherwise)
        const amountDifference = newTotalAmount - (dividendSnapshot.get('amount') as Dividend['amount']);

        const earnings = await getEarnings(dividendRef);

        // update earnings
        await Promise.all(earnings.map(async (earning): Promise<void> => {
          let newEarningAmount: number;
          let newEarningNetAmount: number;
          const oldEarningAmount = earning.amount;
          const oldEarningNetAmount = earning.netAmount;

          if (moment(oldPeriod.toDate()).isSame(moment(period), 'day')) {
            // same date, means we can adjust the amounts in a simple way
            newEarningAmount = new BigNumber(oldEarningAmount).times(newToOldRatio).toNumber();
            newEarningNetAmount = new BigNumber(oldEarningNetAmount).times(newToOldRatio).toNumber();
          } else {
            // we should get the distribution at that point in time for now we'll also use the simple way
            newEarningAmount = new BigNumber(oldEarningAmount).times(newToOldRatio).toNumber();
            newEarningNetAmount = new BigNumber(oldEarningNetAmount).times(newToOldRatio).toNumber();
          }

          const newEarning = createEarning(newEarningAmount, period);
          const investmentRef = bloqifyFirestore.collection('investments').doc(earning.investment.id);
          transaction.update(earning.ref, newEarning);

          // Read investment
          const [getInvestmentError, getInvestmentSuccess] = await to(transaction.get(investmentRef));

          if (getInvestmentError || !getInvestmentSuccess || !getInvestmentSuccess.exists) {
            throw Error(getInvestmentError?.message || 'This investment does not exist');
          }

          const totalEarnings = getInvestmentSuccess.get('totalEarnings') || 0;
          const totalNetEarnings = getInvestmentSuccess.get('totalNetEarnings') || 0;

          // Also update the investment itself
          transaction.update(investmentRef, {
            totalEarnings: new BigNumber(totalEarnings).minus(oldEarningAmount).plus(newEarningAmount).toNumber(),
            totalNetEarnings: new BigNumber(totalNetEarnings).minus(oldEarningNetAmount).plus(newEarningNetAmount).toNumber(),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }));

        const totalDividendAmount = assetSnapshot.get('totalDividendAmount') || 0;

        transaction.update(assetRef, {
          totalDividendAmount: new BigNumber(totalDividendAmount).minus(oldTotalAmount).plus(newTotalAmount).toNumber(),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        const newNetto = new BigNumber(newTotalAmount).minus(newCosts).minus(new BigNumber(newTaxes).dividedBy(100).times(newTotalAmount));

        transaction.update(dividendRef, {
          amount: newTotalAmount,
          taxRate: newTaxes,
          costs: newCosts,
          netAmount: newNetto,
          period: firebase.firestore.Timestamp.fromDate(period),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'updateDividend',
        });
      }

      return commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'updateDividend',
      });
    },
    async deleteDividend(
      { commit },
      deleteDividendParameters: DeleteDividendParam,
    ): Promise<void> {
      commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Processing,
        operation: 'deleteDividend',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(deleteDividendParameters.assetId) as DocumentReference<Asset>;
      const dividendRef = assetRef.collection('dividends').doc(deleteDividendParameters.dividendId) as DocumentReference<Dividend>;

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getDividendError, getDividend] = await to(dividendRef.get());

        if (getDividendError) {
          commit(SET_DIVIDENDS, {
            status: DataContainerStatus.Error,
            payload: getDividendError,
            operation: 'deleteDividend',
          });
          return;
        }

        // delete earnings
        const earnings = await getEarnings(dividendRef);

        const earningUpdates = await Promise.all(earnings.map(async (earning): Promise<any> => {
          // delete the earning
          const earningUpdate: Partial<Earning> = {
            deleted: true,
          };

          // Also update the investment itself
          const investmentRef = bloqifyFirestore.collection('investments').doc(earning.investment.id);

          // Read investment
          const [getInvestmentError, getInvestmentSuccess] = await to(transaction.get(investmentRef));

          if (getInvestmentError || !getInvestmentSuccess || !getInvestmentSuccess.exists) {
            throw Error(getInvestmentError?.message || 'This investment does not exist');
          }

          const totalEarnings = getInvestmentSuccess.get('totalEarnings');
          const totalNetEarnings = getInvestmentSuccess.get('totalNetEarnings');

          const investmentUpdate = {
            totalEarnings: new BigNumber(totalEarnings).minus(earning.amount).toNumber(),
            totalNetEarnings: new BigNumber(totalNetEarnings).minus(earning.netAmount).toNumber(),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          };

          return [
            earning.ref,
            earningUpdate,
            investmentRef,
            {
              totalEarnings,
              totalNetEarnings,
              earningAmount: earning.amount,
              earningNetAmount: earning.netAmount,
            },
          ];
        }));

        const dividend: Dividend = getDividend?.data() as Dividend;

        // Read asset
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));

        if (getAssetError || !getAssetSuccess || !getAssetSuccess.exists) {
          throw Error(getAssetError?.message || 'This investment does not exist');
        }

        const totalDividendAmount = getAssetSuccess.get('totalDividendAmount');

        // update the asset
        transaction.update(assetRef, {
          totalDividendAmount: new BigNumber(totalDividendAmount).minus(dividend.amount).toNumber(),
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        });

        // update Dividend itself
        transaction.update(dividendRef, {
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          deleted: true,
        });

        await Promise.all(earningUpdates.map(async ([earningRef, earningUpdate, investmentRef, investmentUpdateData]): Promise<any> => {
          transaction.update(earningRef, earningUpdate);

          const { totalEarnings, earningAmount, totalNetEarnings, earningNetAmount } = investmentUpdateData;

          transaction.update(investmentRef, {
            totalEarnings: new BigNumber(totalEarnings).minus(earningAmount).toNumber(),
            totalNetEarnings: new BigNumber(totalNetEarnings).minus(earningNetAmount).toNumber(),
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });
        }));
      }));

      if (transactionError) {
        return commit(SET_DIVIDENDS, {
          status: DataContainerStatus.Error,
          payload: transactionError,
          operation: 'deleteDividend',
        });
      }

      return commit(SET_DIVIDENDS, {
        status: DataContainerStatus.Success,
        payload: transactionSuccess,
        operation: 'deleteDividend',
      });
    },
  },
};
