import Vue from 'vue';
import { ActionContext, Module } from 'vuex';
import { ethers } from 'ethers';
import to from 'await-to-js';
import detectEthereumProvider from '@metamask/detect-provider';
import { State, BlockchainStateSlice } from '@/models/State';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { generateState, mutateState, Vertebra } from '../utils/skeleton';
import assetHandlerContractABI from '../../contracts/assetHandlerContract.json';

const SET_BLOCKCHAIN = 'SET_BLOCKCHAIN';

export const trackBlockchainTransactionError = (transactionName, errorMessage, managerId, transactionHash = ''): void => {
  const dateNowTimestamp = firebase.firestore.Timestamp.now();
  const dateNow = dateNowTimestamp.toDate();
  dateNow.setFullYear(dateNow.getFullYear() + 1);
  const expiresAt = firebase.firestore.Timestamp.fromDate(dateNow);

  const errorDocREf = bloqifyFirestore.collection('blockchainTransactionsErrors').doc();
  errorDocREf.set({
    transactionName,
    transactionHash,
    errorMessage,
    managerId,
    createdDateTime: dateNowTimestamp,
    updatedDateTime: dateNowTimestamp,
    expiresAt,
  });
};

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_BLOCKCHAIN](
      state,
      { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string },
    ): void {
      mutateState(state, status, operation, payload);
    },
    updateBlockchainAccountAddress(state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'blockchainAccountAddress', payload.accountAddress);
    },
    updateIsConnectedToBlockchain(state: BlockchainStateSlice, payload: any): void {
      Vue.set(state, 'connectedToBlockchain', payload.connectedToBlockchain);
    },
  },
  actions: {
    async updateWhitelistedBlockchain(
      { commit }: ActionContext<Vertebra, State>,
      { event, investorId, investorMetamaskAccountAddress }: { event: Event, investorId: string, investorMetamaskAccountAddress: string },
    ): Promise<void> {
      commit(SET_BLOCKCHAIN, { status: DataContainerStatus.Processing, operation: 'updateWhitelistedBlockchain' });

      const investorRef = await bloqifyFirestore.collection('investors').doc(investorId);
      const checked = (event.srcElement as HTMLInputElement).checked;
      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        // Read investor
        const [investorError, investorSnapshot] = await to(transaction.get(investorRef));

        if (investorError || !investorSnapshot || !investorSnapshot.exists) {
          throw Error(investorError?.message || 'This investor does not exist');
        }

        const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });

        if (providerDetected) {
          // @ts-ignore
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const proxyContractAddress = '0x10B45FD048d5606a243EDbDBD1DbF9A70BCf889a';
          // The Contract object
          const assetHandler = new ethers.Contract(proxyContractAddress, assetHandlerContractABI, signer);

          const tx = await assetHandler.setWhitelisted([investorMetamaskAccountAddress?.toString()], checked, { gasLimit: 300000 });
          const txResult = await tx.wait();

          if (txResult.status === 1) {
            const dateNow = firebase.firestore.Timestamp.now();
            // save investor as whitelisted or non-whitelisted on database
            transaction.update(
              investorRef,
              {
                blockchainWhitelisted: checked,
                updatedDateTime: dateNow,
              },
            );
          } else {
            trackBlockchainTransactionError(
              'updateWhitelistedBlockchain',
              'Something went wrong.',
              investorId,
              tx.hash,
            );
            throw Error('Blockchain transaction failed. Please try again.');
          }
        } else {
          throw Error('Make sure you have metamask extension installed on your browser.');
        }
      }));

      if (transactionError) {
        return commit(SET_BLOCKCHAIN, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updateWhitelistedBlockchain' });
      }

      return commit(SET_BLOCKCHAIN, {
        status: DataContainerStatus.Success,
        payload: { checked },
        operation: 'updateWhitelistedBlockchain',
      });
    },
     /**
     * We need to set current blockchain updateBlockchainAccountAddress to be able to
     * detect if the user is connected to the blockchain
     */
    updateBlockchainAccountAddress(
      { commit },
      accountAddress: string[],
    ): void {
      commit('updateBlockchainAccountAddress', { accountAddress });
    },
    /**
    * tracker of user blockchain connection
    */
    updateIsConnectedToBlockchain(
      { commit },
      connectedToBlockchain: boolean,
    ): void {
      commit('updateIsConnectedToBlockchain', { connectedToBlockchain });
    },
  },
};
