import { Module } from 'vuex';
import to from 'await-to-js';
import { ethers } from 'ethers';
import BigNumber from 'bignumber.js';
import { State } from '@/models/State';
import detectEthereumProvider from '@metamask/detect-provider';
import { bloqifyFirestore, bloqifyStorage, firebase, bloqifyFunctions } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { Payment } from '@/models/investments/Investment';
import { trackBlockchainTransactionError } from '@/store/modules/blockchain';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { generateFileMd5Hask } from '../utils/files';
import assetHandlerContractABI from '../../contracts/assetHandlerContract.json';

const SET_ASSET = 'SET_ASSET';

export const assetChecks = (asset: Asset): boolean => {
  const requiredFields = [
    'name', 'city', 'country', 'startDateTime', 'endDateTime', 'investmentCase', 'propertyDetails',
    'euroMin', 'totalValueEuro', 'sharePrice', 'emissionCost', 'dividendsFormat',
  ];

  // Allow zeroes
  if (requiredFields.some((field): boolean => !asset[field] && asset[field] !== 0)) {
    return false;
  }

  if (asset.dividendsFormat.length === 0) {
    return false;
  }

  return true;
};

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_ASSET](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async getAssetById(
      { commit },
      { id }: { id: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'getAssetById' });

      const [getAssetError, getAssetData] = await to(bloqifyFirestore.collection('assets').doc(id).get());
      const error = getAssetError || (!getAssetData?.exists && Error('Not Found'));
      if (error) {
        commit(SET_ASSET, { status: DataContainerStatus.Error, payload: error, operation: 'getAssetById' });
        return;
      }

      commit(SET_ASSET, { status: DataContainerStatus.Success, payload: getAssetData!.data(), operation: 'getAssetById' });
    },
    async createAsset(
      { commit },
      { asset, connectedToBlockchain, managerId }: { asset: Asset, connectedToBlockchain: boolean, managerId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'createAsset' });

      const dateNow = firebase.firestore.Timestamp.now();
      const storageRef = bloqifyStorage.ref();
      const assetRef = bloqifyFirestore.collection('assets').doc();
      const valuationRef = assetRef.collection('valuations').doc();
      let lastId = 0;
      const [getLastAssetError, assetsQueryResult] = await to(
        bloqifyFirestore.collection('assets').orderBy('customId', 'desc').limit(1).get(),
      );
      if (getLastAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: getLastAssetError, operation: 'createAsset' });
      }

      if (assetsQueryResult?.size === 1) {
        lastId = assetsQueryResult.docs[0].get('customId');
      }

      const customId = Number(lastId + 1);
      const firstBlockchainTokenId = (customId * 1000000000).toString();
      const assetClone = { ...asset, customId };
      const files: { [key: string]: File[] } = {};
      const storageChildren: { file: File, ref: firebase.storage.Reference }[] = [];
      const filesKeyNames = ['images', 'floorPlanImages', 'prospectus', 'brochure', 'legalDocuments'];

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNames.forEach((keyName): void => {
        assetClone[keyName] = assetClone[keyName].map((file: File): string | null => {
          if (!file) {
            return null;
          }

          const fullPath = `assets/${assetRef.id}/${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // Uploading all files including hashes
      try {
        await Promise.all(storageChildren.map(async (child): Promise<any> => {
          const md5Hash = await generateFileMd5Hask(child.file, true);

          return child.ref.put(child.file, { customMetadata: { md5Hash } });
        }));
      } catch (e) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'createAsset' });
      }

      // Data fixing
      if (assetClone.startDateTime) {
        // @ts-ignore
        assetClone.startDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.startDateTime);
      }
      if (assetClone.endDateTime) {
        // @ts-ignore
        assetClone.endDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.endDateTime);
      }

      // @ts-ignore
      assetClone.createdDateTime = firebase.firestore.FieldValue.serverTimestamp();

      // @ts-ignore
      assetClone.updatedDateTime = firebase.firestore.FieldValue.serverTimestamp();

      const updateTotalValueShares = (): number => {
        if (!isNaN(assetClone.totalValueEuro) && !isNaN(assetClone.sharePrice)) {
          // It can be 0/0 = NaN
          return (assetClone.totalValueEuro / assetClone.sharePrice) || 0;
        }

        return 0;
      };

      assetClone.totalValueShares = updateTotalValueShares();
      assetClone.sharesAvailable = assetClone.totalValueShares;
      assetClone.totalValueEuro = assetClone.totalValueEuro || 0;
      assetClone.euroMin = assetClone.euroMin || 0;
      assetClone.sharePrice = assetClone.sharePrice || 0;
      assetClone.deleted = false;
      assetClone.blockchainTokensMinted = 0;
      assetClone.availableBlockchainTokenId = firstBlockchainTokenId;

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        transaction.set(valuationRef, {
          asset: assetRef,
          amount: assetClone.totalValueEuro,
          description: 'Initial valuation',
          date: dateNow,
          createdDateTime: dateNow,
          deleted: false,
        });

        const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
        // If the admin is connected to the blockchain let's run the transaction to create it
        // on the blockchain too
        if (connectedToBlockchain && providerDetected) {
          // @ts-ignore
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const proxyContractAddress = '0x10B45FD048d5606a243EDbDBD1DbF9A70BCf889a'; // '0x76D544C9879853c491A1B0210eC18DB2e6eDF49C';
          // The Contract object
          const daiContract = new ethers.Contract(proxyContractAddress, assetHandlerContractABI, signer);

          const tx = await daiContract.createAsset(
            customId,
            ethers.BigNumber.from(assetClone.totalValueShares),
            { gasLimit: 3000000 },
          );
          const txResult = await tx.wait();

          if (txResult.status === 0) {
            trackBlockchainTransactionError(
              'createAsset',
              'Something went wrong.',
              managerId,
              tx.hash,
            );
            throw Error('Blockchain transaction failed');
          }

          assetClone.createdOnBlockchain = true;
        } else {
          assetClone.createdOnBlockchain = false;
        }

        transaction.set(assetRef, assetClone);
      }));

      if (transactionError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createAssets' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, payload: { id: assetRef.id }, operation: 'createAsset' });
    },
    async updateAsset(
      { commit },
      { asset }: { asset: Asset },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'updateAsset' });

      const storageRef = bloqifyStorage.ref();
      const { id: assetId, ...assetClone } = asset;
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const files: { [key: string]: File[] } = {};
      const storageChildren: { file: File, ref: firebase.storage.Reference }[] = [];
      const filesKeyNames = ['images', 'floorPlanImages', 'prospectus', 'brochure', 'legalDocuments'];

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNames.forEach((keyName): void => {
        assetClone[keyName] = assetClone[keyName].map((file: File): string | null => {
          if (!file) {
            return null;
          }

          const fullPath = `assets/${assetRef.id}/${file.name}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // The comparison of the md5Hash could have been done here via JavaScript (customMetadata.md5Hash) but it's also possible via
      // Firestore rules. The only caveat is that the error handling is not good at all, we cannot identify
      // what kind of error we are getting from the rules, only no permission.
      let storageResultsAndErrors: firebase.storage.UploadTaskSnapshot | firebase.functions.HttpsError[];
      try {
        storageResultsAndErrors = await Promise.all(storageChildren.map(async (child): Promise<any> => {
          const md5Hash = await generateFileMd5Hask(child.file, true);

          // Return all errors if there are any
          return child.ref.put(child.file, { customMetadata: { md5Hash } })
            .catch((err): Error => err);
        }));
      } catch (e) {
        // Set error if there is any other kind of error than a FirebaseStorageError
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'updateAsset' });
      }

      // Check if there is any other FirebaseStorageError error than 'storage/unauthorized',
      // since it's the only one we have to check if the md5 exists (check rules)
      const differentError = storageResultsAndErrors.some(
        // @ts-ignore (types are not correct, it does not support code 'storage/unauthorized')
        (resultOrError): boolean => resultOrError.code && resultOrError.code !== 'storage/unauthorized',
      );
      if (differentError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error('Error uploading files.'), operation: 'updateAsset' });
      }

      // Data fixing
      if (assetClone.startDateTime) {
        // @ts-ignore
        assetClone.startDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.startDateTime);
      }
      if (assetClone.endDateTime) {
        // @ts-ignore
        assetClone.endDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.endDateTime);
      }

      // @ts-ignore
      assetClone.updatedDateTime = firebase.firestore.FieldValue.serverTimestamp();

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists) {
          throw getAssetError || Error('Asset not found.');
        }

        const dbAsset = getAssetSuccess?.data() as Asset;

        const updateAssetObject: { [key: string]: any } = {
          ...assetClone,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        };

        // We don't need to update shares fields if these fields remain the same
        if (dbAsset.totalValueEuro !== asset.totalValueEuro || dbAsset.sharePrice !== asset.sharePrice) {
          // This should have been queried with transaction but due to the fact that transaction.get (frontend only) does not accept queries,
          // it's not possible to do it.
          const [getInvestmentsError, getInvestmentsSuccess] = await to(
            bloqifyFirestore.collection('investments').where('asset', '==', assetRef).where('boughtSharesTotal', '>', 0).get(),
          );
          if (getInvestmentsError) {
            throw getInvestmentsError;
          }

          const [getPaymentsError, getPaymentsSuccess] = await to(
            bloqifyFirestore.collectionGroup('payments').where('asset', '==', assetRef).where('providerData.status', '==', 'open').get(),
          );
          if (getPaymentsError) {
            throw getPaymentsError;
          }

          const payments = getPaymentsSuccess!.docs.map((payment): Payment => payment.data() as Payment);

          const updateTotalValueShares = (): number => {
            if (!isNaN(assetClone.totalValueEuro) && !isNaN(assetClone.sharePrice)) {
              // It can be 0/0 = NaN
              return (assetClone.totalValueEuro / assetClone.sharePrice) || 0;
            }

            return 0;
          };

          const totalValueShares = updateTotalValueShares();
          const boughtSharesTotal = getInvestmentsSuccess!.docs.reduce(
            (docA, docB): number => (docA || 0) + docB.get('boughtSharesTotal'),
            0,
          );
          const openPaymentsTotalShares = payments.reduce((a, payment): number => a + payment.providerData.metadata.sharesAmount, 0);

          updateAssetObject.totalValueShares = totalValueShares;
          updateAssetObject.sharesAvailable = totalValueShares - (boughtSharesTotal + openPaymentsTotalShares);
        }

        transaction.update(assetRef, updateAssetObject);
      }));
      if (transactionError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updateAsset' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, payload: asset, operation: 'updateAsset' });
    },
    async handlePublishAssetById(
      { commit },
      { assetId, published }: { assetId: string, published: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handlePublishAssetById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

      const [handlePublishAssetError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
          throw getAssetError || Error('Asset does not exist');
        }

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        transaction.update(
          assetRef,
          {
            published,
            updatedDateTime: serverTimestamp,
          },
        );
        transaction.set(
          bloqifyFirestore.collection('settings').doc('counts'),
          {
            publishedAssets: firebase.firestore.FieldValue.increment(published ? 1 : -1),
            updatedDateTime: serverTimestamp,
          },
          {
            merge: true,
          },
        );
      }));
      if (handlePublishAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: handlePublishAssetError, operation: 'handlePublishAssetById' });
      }

      const [handlePublishGetAssetError, getAssetSuccess] = await to(assetRef.get());
      if (handlePublishGetAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: handlePublishGetAssetError, operation: 'handlePublishAssetById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handlePublishAssetById' });
    },
    async handleMintAssetTokensOnBlockchainById(
      { commit },
      { assetId, managerId }: { assetId: string, managerId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleMintAssetTokensOnBlockchainById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const [getAssetError, getAssetSuccess] = await to(assetRef.get());
      if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: getAssetError,
          operation: 'handleMintAssetTokensOnBlockchainById',
        });
      }

      const assetCustomId = getAssetSuccess.get('customId');

      const assetSharesAmount = getAssetSuccess.get('totalValueShares');
      const blockchainTokensMinted = getAssetSuccess.get('blockchainTokensMinted');
      if (blockchainTokensMinted === assetSharesAmount) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: { message: 'Asset tokens already minted' },
          operation: 'handleMintAssetTokensOnBlockchainById',
        });
      }

      const [getMintAssetTokensError] = await to(bloqifyFunctions.httpsCallable('mintAssetTokensOnBlockchain')(
        {
          numberOfTokensToMint: assetSharesAmount - blockchainTokensMinted,
          assetCustomId,
          assetId,
          managerId,
        },
      ));

      if (getMintAssetTokensError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          payload: getMintAssetTokensError,
          operation: 'handleMintAssetTokensOnBlockchainById',
        });
      }

      return commit(SET_ASSET, {
        status: DataContainerStatus.Success,
        payload: { minted: true },
        operation: 'handleMintAssetTokensOnBlockchainById',
      });
    },
    async createAssetOnBlockchain(
      { commit },
      { assetCustomId, connectedToBlockchain, managerId }: { assetCustomId: string, connectedToBlockchain: boolean, managerId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'createAssetOnBlockchain' });

      const [getAssetError, assetQueryResult] = await to(
        bloqifyFirestore.collection('assets').where('customId', '==', assetCustomId).limit(1).get(),
      );
      if (getAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: getAssetError, operation: 'createAssetOnBlockchain' });
      }

      if (assetQueryResult!.empty) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error('Asset not found'), operation: 'createAssetOnBlockchain' });
      }

      const asset = assetQueryResult!.docs[0];
      const assetRef = asset!.ref;
      const totalValueShares = asset!.get('totalValueShares');
      const dateNow = firebase.firestore.Timestamp.now();

      const [transactionError, transactionSuccess] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const providerDetected = await detectEthereumProvider({ mustBeMetaMask: true });
        // If the admin is connected to the blockchain let's run the transaction to create it
        // on the blockchain too
        if (!(connectedToBlockchain && providerDetected)) {
          throw Error('Make sure you have Metamask activated and Account connected.');
        }

        // @ts-ignore
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const proxyContractAddress = '0x10B45FD048d5606a243EDbDBD1DbF9A70BCf889a';
        // The Contract object
        const daiContract = new ethers.Contract(proxyContractAddress, assetHandlerContractABI, signer);

        const tx = await daiContract.createAsset(
          assetCustomId,
          ethers.BigNumber.from(totalValueShares),
          { gasLimit: 3000000 },
        );
        const txResult = await tx.wait();

        if (txResult.status === 0) {
          trackBlockchainTransactionError(
            'createAssetOnBlockchain',
            'Something went wrong.',
            managerId,
            tx.hash,
          );
          throw Error('Blockchain transaction failed');
        }

        // Mark in our System that the asset was created on the blockchain
        // so we can now allow the admin to mint the tokens
        transaction.update(assetRef, {
          createdOnBlockchain: true,
          updatedDateTime: dateNow,
        });
      }));

      if (transactionError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createAssetOnBlockchain' });
      }
      return commit(SET_ASSET, { status: DataContainerStatus.Success, payload: { id: assetRef?.id }, operation: 'createAssetOnBlockchain' });
    },
    async handleDeleteAssetById(
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleDeleteAssetById' });

      const [updateAssetError] = await to(bloqifyFirestore.collection('assets').doc(assetId).update(
        {
          deleted: true,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        },
      ));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handleDeleteAssetById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handleDeleteAssetById' });
    },
  },
  getters: {
    getAssetTotalEuroInvested: (state, getters): Function => (asset: Asset): number => {
      const assetActiveValuation = getters.getActiveValuationByAsset(asset.id);

      if (!assetActiveValuation) {
        return new BigNumber(asset.totalValueShares).minus(asset.sharesAvailable).times(asset.sharePrice).toNumber();
      }

      const sharePrice = assetActiveValuation
        ? new BigNumber(assetActiveValuation.amount).dividedBy(asset.totalValueShares).toNumber()
        : asset.sharePrice;

      return new BigNumber(asset.totalValueShares).minus(asset.sharesAvailable)
        .times(sharePrice)
        .decimalPlaces(2)
        .toNumber();
    },
  },
};
