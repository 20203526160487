
  import { Component, Vue, Watch } from 'vue-property-decorator';
  import { ethers } from 'ethers';
  import to from 'await-to-js';
  import { Action, Getter, State as StateClass } from 'vuex-class';
  import changeCase from 'change-case';
  import Web3 from 'web3';
  // @ts-ignore
  import { Toast, ADD_TOAST_MESSAGE as addToastMessage } from 'vuex-toast';
  import { firebase, bloqifyFirestore } from '@/boot/firebase';
  import { BlockchainStateSlice, State } from '@/models/State';
  import Sidebar from '@/components/navigation/Sidebar.vue';
  import { ManagerRole } from '@/models/manager/Manager';
  import { CurrentManager } from '@/models/manager/CurrentManager';
  import initialiseValidators from '@/boot/validators';
  import assetHandlerContractABI from './contracts/assetHandlerContract.json';

  initialiseValidators();

  @Component({
    components: {
      Toast,
      Sidebar,
    },
  })
  export default class App extends Vue {
    defaultLayout: string = 'single';

    @Action refreshAuthenticatedUserToken!: Function;
    @Action logOut!: Function;
    @Action(addToastMessage) addToastMessage!: Function;
    @Action updateBlockchainAccountAddress!: Function;
    @Action updateIsConnectedToBlockchain!: Function;

    @StateClass manager!: State['manager'];
    @StateClass currentManager!: State['currentManager'];
    @StateClass settings!: State['settings'];
    @StateClass blockchain!: BlockchainStateSlice['blockchainAccountAddress'];

    @Getter getCurrentManagerRole!: ManagerRole;
    @Getter getCurrentManager!: CurrentManager;

    async mounted() {
      // @ts-ignore
      const { ethereum } = window;
      if (ethereum && ethereum.on) {
        const web3 = new Web3();
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const proxyContractAddress = '0x10B45FD048d5606a243EDbDBD1DbF9A70BCf889a'; // '0x76D544C9879853c491A1B0210eC18DB2e6eDF49C';
        // The Contract object
        const daiContract = new ethers.Contract(proxyContractAddress, assetHandlerContractABI, signer);
        // Receive an event when ANY transfer occurs
        daiContract.on('Transfer', async (from, to, amount, event) => {
          const tokenId = web3.utils.toDecimal(event.topics[event.topics.length - 1]).toString();
          const assetId = this.$route.params.assetId;
          if (this.$route.fullPath.includes('create-modify-asset')) {
            const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
            const assetSnapshot = await assetRef.get();
            const assetCustomId = assetSnapshot.get('customId');
            const assetTotalValueShares = assetSnapshot.get('totalValueShares');
            const lastTokenId = ((Number(assetCustomId) * 1000000000) + Number(assetTotalValueShares) - 1).toString();

            if (to === proxyContractAddress && tokenId === lastTokenId) {
              this.addToastMessage({
                text: 'Asset tokens minted on Blockchain with success',
                type: 'success',
              });
              const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();
              assetRef.update(
                {
                  tokensMinted: true,
                  updatedDateTime: serverTimestamp,
                },
              );
            }
          }
        });

        // Check if a metamask account is connected
        // @ts-ignore
        ethereum.request({ method: 'eth_accounts' }).then(
          (accounts) => {
            if (accounts?.length > 0) {
              this.updateBlockchainAccountAddress(accounts[0]);
            }
          },
        );

        ethereum.on('accountsChanged', (accountAddress) => {
          this.updateBlockchainAccountAddress(accountAddress);
        });
      }
    }

    beforeUnmount() {
      // @ts-ignore
      const { ethereum } = window;
      if (ethereum.removeListener) {
        ethereum.removeListener('accountsChanged');
      }
    }

    @Watch('blockchain.blockchainAccountAddress', { immediate: true })
    onNewBlockchainAccountAddress(newBlockchainAccountAddress: string[]): void {
      if (newBlockchainAccountAddress && newBlockchainAccountAddress.length > 0) {
        this.updateIsConnectedToBlockchain(true);
      } else {
        this.updateIsConnectedToBlockchain(false);
      }
    }

    @Watch('manager', { deep: true })
    onFireStoreUserChange(newState: State['manager'], oldState: State['manager']): void {
      if (newState !== null && oldState !== null) {
        // Role changed, so make sure client side we're up to date
        if (newState.metadata.roleSetTime !== oldState.metadata.roleSetTime) {
          this.refreshAuthenticatedUserToken();
        }
      }
    }

    /**
     * Log out user when idle.
     */
    @Watch('isAppIdle')
    onNewAppIdle(newAppIdle: boolean, oldAppIddle: boolean): void {
      if (newAppIdle && !oldAppIddle && this.currentManager) {
        this.logOut({ redirect: '/login', idle: newAppIdle });
      }
    }

    /**
     * Computed property to return the layout defined
     * in router.ts as meta property. Returns CamelCased
     * layout (i.e. Vue component defined in layouts.ts).
     */
    get layout(): string {
      const layout: string = this.$route.meta?.layout || this.defaultLayout;

      return changeCase.pascalCase(`${layout}-layout`);
    }

    get isUserSuperAdmin(): boolean {
      if (this.getCurrentManagerRole) {
        return (this.getCurrentManagerRole === ManagerRole.Superadmin);
      }
      return false;
    }

    get maintenanceMode(): boolean {
      if (!this.settings) {
        return false;
      }
      return this.settings.bloqadminMaintenance;
    }
  }

