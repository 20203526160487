import { Module } from 'vuex';
import { State } from '@/models/State';
import asset from './asset';
import user from './user';
import identificationRequest from './identificationRequest';
import payment from './payment';
import valuation from './valuation';
import pescheck from './pescheck';
import dividend from './dividend';
import blockchain from './blockchain';
import { Vertebra } from '../utils/skeleton';

export default <{ [key: string]: Module<Vertebra, State> }>{
  asset,
  user,
  identificationRequest,
  payment,
  valuation,
  pescheck,
  dividend,
  blockchain,
};
