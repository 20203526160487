import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { generateState, mutateState, Vertebra } from '@/store/utils/skeleton';
import { Asset } from '@/models/assets/Asset';
import { Valuation } from '@/models/assets/Valuation';
import DocumentReference = firebase.firestore.DocumentReference;
import Timestamp = firebase.firestore.Timestamp;

const SET_VALUATION = 'SET_VALUATION';

export interface UpdateValuationParam {
  assetId: string,
  valuationId?: string,
  updatedValuation: object;
}

export interface DeleteValuationParam {
  assetId: string,
  valuationId?: string,
}

export interface AddValuationParam {
  assetId: string;
  amount: number;
  description: string;
  date: Timestamp;
}

export default <Module<Vertebra, State>>{
  state: generateState(),
  mutations: {
    [SET_VALUATION](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addValuation(
      { commit }, { assetId, amount, description, date }: AddValuationParam,
    ): Promise<void> {
      commit(SET_VALUATION, { status: DataContainerStatus.Processing, operation: 'addValuation' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId) as DocumentReference<Asset>;
      const valuationsRef = assetRef.collection('valuations');
      const dateNow = firebase.firestore.Timestamp.now();

      const [getAssetError, getAsset] = await to(assetRef.get());
      if (getAssetError || !getAsset) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: Error(getAssetError?.message || 'Asset not found'),
          operation: 'addValuation',
        });
      }
      const asset = getAsset.data() as Asset;
      const sharePrice = amount / asset.totalValueShares;
      // A number minus the same number truncated leaves the decimals, we check that the length of this decimals
      // is not above 2 digits (4 counting the 0.)
      if ((sharePrice - Math.trunc(sharePrice)).toString().length > 4) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: 'New share price exceed 2 decimal`s',
          operation: 'addValuation',
        });
      }

      const [addValuationError, addValuationSuccess] = await to( // write answers to firestore
        valuationsRef.add({
          asset: assetRef,
          amount,
          description,
          date,
          createdDateTime: dateNow,
          deleted: false,
        }),
      );

      if (addValuationError) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: addValuationError,
          operation: 'addValuation',
        });
      }

      return commit(SET_VALUATION, {
        status: DataContainerStatus.Success,
        payload: addValuationSuccess,
        operation: 'addValuation',
      });
    },
    async updateValuation(
      { commit },
      { updatedValuation, assetId, valuationId }: UpdateValuationParam,
    ): Promise<void> {
      commit(SET_VALUATION, {
        status: DataContainerStatus.Processing,
        operation: 'updateValuation',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const valuationRef = assetRef.collection('valuations').doc(valuationId) as DocumentReference<Valuation>;

      const [getAssetError, getAsset] = await to(assetRef.get());
      if (getAssetError || !getAsset) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: Error(getAssetError?.message || 'Asset not found'),
          operation: 'updateValuation',
        });
      }
      const asset = getAsset.data() as Asset;
      const sharePrice = (updatedValuation as Valuation).amount / asset.totalValueShares;
      // A number minus the same number truncated leaves the decimals, we check that the length of this decimals
      // is not above 2 digits (4 counting the 0.)
      if ((sharePrice - Math.trunc(sharePrice)).toString().length > 4) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: 'New share price exceed 2 decimal`s',
          operation: 'updateValuation',
        });
      }
      const [updateValuationError, updateValuationSuccess] = await to( // write answers to firestore
        valuationRef.update(updatedValuation),
      );
      if (updateValuationError) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: updateValuationError,
          operation: 'updateValuation',
        });
      }

      return commit(SET_VALUATION, {
        status: DataContainerStatus.Success,
        payload: updateValuationSuccess,
        operation: 'updateValuation',
      });
    },
    async deleteValuation(
      { commit },
      { assetId, valuationId }: DeleteValuationParam,
    ): Promise<void> {
      commit(SET_VALUATION, {
        status: DataContainerStatus.Processing,
        operation: 'deleteValuation',
      });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const valuationRef = assetRef.collection('valuations').doc(valuationId) as DocumentReference<Valuation>;

      const [deleteValuationError, deleteValuationSuccess] = await to(
        valuationRef.update({ deleted: true }),
      );
      if (deleteValuationError) {
        return commit(SET_VALUATION, {
          status: DataContainerStatus.Error,
          payload: deleteValuationError,
          operation: 'deleteValuation',
        });
      }

      return commit(SET_VALUATION, {
        status: DataContainerStatus.Success,
        payload: deleteValuationSuccess,
        operation: 'deleteValuation',
      });
    },
  },
  getters: {
    getActiveValuationByAsset: (state, getters, rootState): Function => (assetId: string): Valuation | undefined => {
      const dateNow = new Date();
      return rootState.valuations.find(
        (valuation): boolean => valuation.asset.id === assetId && valuation.date <= firebase.firestore.Timestamp.fromMillis(
          Date.UTC(dateNow.getFullYear(),
          dateNow.getMonth(),
          dateNow.getDate()),
        ),
      );
    },
  },
};
