import VeeValidate, { extend } from 'vee-validate';
import {
  max as _max,
  min as _min,
  required as _required,
  alpha_num as _alpha_num,
  integer as _integer,
  confirmed as _confirmed,
  email as _email,
  ext as _ext,
} from 'vee-validate/dist/rules';
import moment from 'moment';

const initialiseValidators = (): void => {
  extend('required', {
    ..._required,
    message: 'This field is required',
  });
  extend('max', {
    ..._max,
    message: 'This field has a maximum length',
  });
  extend('email', {
    ..._email,
    message: 'This field must contain a valid email address',
  });
  extend('min', {
    ..._min,
    message: 'This field has a minimum {length} length',
  });

  extend('alpha_num', {
    ..._alpha_num,
    message: 'This field can only contain alphanumerics',
  });

  extend('integer', {
    ..._integer,
    message: 'This field can only contain integers',
  });
  extend('confirmed', {
    ..._confirmed,
    message: 'The passwords must match',
  });

  extend('ext', {
    ..._ext,
    message: (rule, values): string => {
      const fileTypeKeys: string[] = Object.keys(values).filter((key: string): any => !isNaN(parseFloat(key)));
      const fileTypes: string[] = fileTypeKeys.map((key: string): string => values[key].toUpperCase());

      return `File type must be of type ${fileTypes.slice(0, -1).join(', ')} or ${fileTypes.slice(-1)[0]}`;
    },
  });

  extend('strong_password', (value): boolean | string => {
    const message = 'The password must contain at least 1 uppercase letter, 1 lowercase letter, ' +
      '1 number, and one special character (for example: $&*!@).';
    // eslint-disable-next-line no-useless-escape
    const strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.*[0-9])(?!.*[\ ])\\S*$');
    return strongRegex.test(value) ? true : message;
  });
  extend('decimal', (value): boolean | string => {
    const message = 'The field has to contain a number';
    const decimalRegex = RegExp('^[0-9]*([.,])?[0-9]*$');
    return decimalRegex.test(value) ? true : message;
  });

  extend('multiplier', {
    validate(value: any, args): boolean | string {
      // @ts-ignore
      const base = Number(args.base); // share price
      const multip = Number(value); // min
      const message = 'The minimum amount to participate has to be a multiplier of the price per share.';
      return (multip % base === 0) && (base <= multip) ? true : message;
    },
    params: ['base'],
  });

  extend('aftereqtimestamp', {
    validate(value: any, args: any): boolean | string {
      const baseDate = moment(args.baseDate / 1);
      const otherDate = moment(args.otherDate / 1);
      const message = `This date has to be after ${baseDate.format('DD/MM/YYYY')}`;
      return (otherDate.isSameOrAfter(baseDate, 'day')) ? true : message;
    },
    params: ['baseDate', 'otherDate'],
  });

  extend('beforeeqtimestamp', {
    validate(value: any, args: any): boolean | string {
      const baseDate = moment(args.baseDate / 1);
      const otherDate = moment(args.otherDate / 1);
      const message = `This date has to be before ${baseDate.format('DD/MM/YYYY')}`;
      return (otherDate.isSameOrBefore(baseDate, 'day')) ? true : message;
    },
    params: ['baseDate', 'otherDate'],
  });
};
export default initialiseValidators;
